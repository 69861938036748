import React from 'react';
import { Layout } from '../components/Layout';
import { PrivateRoute } from '../components/PrivateRoute';
import { Footer } from '../modules/Footer';
import { Menu } from '../modules/Menu';
import { UserManagement } from '../modules/UserManagement';

const userManagementWrapper = ({ location }: any) => (
  <div className="bg-wild-sand">
    <Layout>
      <Menu menuHasTwoColors={false} />
      <UserManagement locale={location.href} />
      <Footer />
    </Layout>
  </div>
);

const userManagementPage = ({ ...props }) => (
  <PrivateRoute
    location={props.location.pathname}
    locale={props.location.href}
    component={userManagementWrapper}
  />
);

export default userManagementPage;
