import React, { Component } from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _isEqual from 'lodash/isEqual';
import { inject, observer } from 'mobx-react';
import { DataTable } from '../../components/DataTable';
import { LoaderFullPage } from '../../components/Loaders';
import { Localities } from '../../components/Location/services/AutoCompleteCity';
import { Pagination } from '../../components/Pagination';
import { PrintContent } from '../../components/PrintContent';
import { formatCurrency } from '../../utils/formatNumber';
import { getParameterByName } from '../../utils/functions';
import { StoreFromService, TypeClientStore } from '../SubscriptionPlans/store/selectedStore';
import { IUserGetParams, UserService } from './services';
import style from './UserManagement.module.css';
import { getFilterTypeOptions, getOrderingOptions } from './utils';

// Just for build

interface IOption {
  id: string | null;
  label: string | null;
  type: string | null;
  value: string | null;
  isChecked: boolean | null;
}

interface ITypeParamFilter {
  value: number;
  label: string;
}

interface ICityParamFilter {
  value: string | null | any;
  label: string | null | any;
}

interface IPaginationParam {
  selected?: number;
}

interface IPushState {
  user?: string;
  location?: string;
  type?: string | null;
  selectedPage?: number;
}

interface Props {
  locale?: any;
  selectedStore?: TypeClientStore;
  search?: string;
}

@inject('selectedStore')
@observer
export class UserManagement extends Component<Props> {
  state = {
    isLoading: true,
    source: [],
    totalCounter: 0,
    sourceDataPrint: [],
    orderingOptions: [],
    filterTypeOptions: [],
    filterCityOptions: [],
    showNoOptions: false,
    selectedOrdering: null,
    activeFilters: [],
    params: {
      page: 0,
      take: 10,
      totalPages: 0,
      searchBoxValue: '',
      selectedFilterLocation: { label: '', value: null },
      selectedFilterType: { label: '', value: null },
      selectedOrdering: {
        id: null,
        label: null,
        type: null,
        value: null,
        isChecked: null,
      },
    },
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.locale !== this.props.locale) {
      this.updatePage();
    }
  }

  componentDidMount() {
    this.updatePage();
  }

  updatePage() {
    const params = this.forceStatePerQueryString();

    this.setState(
      {
        orderingOptions: getOrderingOptions(),
        filterTypeOptions: getFilterTypeOptions(),
        params,
      },
      () => {
        this.getSource();
      },
    );
  }

  pushStateQueryString = (pushState: IPushState) => {
    let { user, location, type, selectedPage } = pushState;
    const {
      params: { page, selectedFilterType, selectedFilterLocation, searchBoxValue },
    } = this.state;
    let strQueryString = '';

    selectedPage = selectedPage || page;
    strQueryString += `page=${selectedPage + 1}&`;

    user = user || searchBoxValue;
    strQueryString += user ? `user=${user}&` : '';

    location = location || selectedFilterLocation.label;
    strQueryString += location ? `location=${location}&` : '';

    type = type || selectedFilterType.label;
    strQueryString += type ? `type=${type}` : '';

    navigate(`/gerenciamento-usuarios?${strQueryString}`, { state: { lastUrl: strQueryString } });
  };

  getSource = () => {
    const {
      params: {
        page,
        take,
        searchBoxValue,
        selectedFilterType,
        selectedFilterLocation,
        selectedOrdering,
      },
    } = this.state;

    const normalizedParams: IUserGetParams = {
      page,
      take,
      name: searchBoxValue,
      type: null,
      city: null,
      order: null,
    };

    const filters = [];

    if (selectedFilterType && selectedFilterType.value) {
      normalizedParams.type = selectedFilterType.value === 3 ? null : selectedFilterType.value;
      filters.push({
        type: 'tipo',
        active: selectedFilterType,
      });
    }

    if (selectedFilterLocation && selectedFilterLocation.value) {
      normalizedParams.city = selectedFilterLocation.value;
      filters.push({
        type: 'localizacao',
        active: selectedFilterLocation,
      });
    }

    filters.push({
      type: 'text',
      active: { label: searchBoxValue, value: searchBoxValue },
    });

    if (selectedOrdering) {
      const { type, value } = selectedOrdering;
      if (type && value) {
        normalizedParams.order = { by: type, type: value };
      }
    }

    const calcTotalPages = (totalResults: number) => {
      const calc = Math.ceil(totalResults / take);
      if (isNaN(calc)) {
        return 0;
      }
      return calc;
    };
    this.setState(
      {
        isLoading: true,
        activeFilters: filters,
      },
      () => {
        UserService.get(normalizedParams)
          .then((response: any) => {
            const { data, headers } = response;
            const contentRange =
              !headers || headers['content-range'] === 'null'
                ? data.length
                : headers['content-range'];
            this.setState(
              {
                ...this.state,
                source: data,
                totalCounter: contentRange,
                params: {
                  ...this.state.params,
                  totalPages: calcTotalPages(contentRange),
                },
              },
              () => {
                this.setState({
                  isLoading: false,
                });
              },
            );
          })
          .catch(() => navigate('/meus-anuncios'));
      },
    );
  };

  forceStatePerQueryString = () => {
    const { searchBoxValue, selectedFilterLocation, selectedFilterType } = this.state.params;
    const queryString = (param: any) => getParameterByName(param);
    const typeValue = (typeSelected: any) => {
      if (typeSelected === 'Loja') {
        return 2;
      }
      if (typeSelected === 'Pessoa') {
        return 1;
      }

      const arCityState = typeSelected.split('-');

      return arCityState && arCityState[0].trim();
    };

    const selectedPage = queryString('page') ? parseInt(queryString('page'), 10) : 0;
    const userFilter = queryString('user') ? queryString('user') : searchBoxValue;
    const type = queryString('type');
    const location = queryString('location');

    const typeFilter = type ? { label: type, value: typeValue(type) } : selectedFilterType;
    const locationFilter = location
      ? { label: location, value: typeValue(location) }
      : selectedFilterLocation;

    return {
      ...this.state.params,
      page: selectedPage > 0 ? selectedPage - 1 : 0,
      searchBoxValue: userFilter,
      selectedFilterType: typeFilter,
      selectedFilterLocation: locationFilter,
    };
  };

  handlePaginationClick = (arSelected: IPaginationParam[]) => {
    const { params } = this.state;
    const requestPage = _get(arSelected, 'selected', 0);

    this.setState(
      {
        params: {
          ...params,
          page: requestPage,
        },
      },
      () => {
        this.pushStateQueryString({ selectedPage: requestPage });
        this.getSource();
      },
    );
  };

  handleTableSearch = (value: string) => {
    const { params } = this.state;
    const { page } = params;

    this.setState(
      {
        ...this.state,
        params: {
          ...params,
          page,
          searchBoxValue: value,
        },
      },
      () => {
        this.pushStateQueryString({ user: value, selectedPage: page });
        this.getSource();
      },
    );
  };

  handleOrdering = (option: IOption) => {
    const { orderingOptions, params } = this.state;
    const { page } = params;
    const activeOrdering = orderingOptions.map((item: IOption) => {
      item.isChecked = false;
      if (_isEqual(item, option)) {
        item.isChecked = true;
      }
      return item;
    });
    this.setState(
      {
        orderingOptions: activeOrdering,
        params: {
          ...params,
          page,
          selectedOrdering: option,
        },
      },
      () => {
        this.getSource();
      },
    );
  };

  handleTypeFilter = (option: ITypeParamFilter, hasOutsideControl?: boolean) => {
    const { params } = this.state;
    const normalizeOption = option.value === 3 ? { label: '', value: null } : option;
    const { page } = params;
    if (hasOutsideControl) {
      return this.setState(
        {
          params: {
            ...params,
            page,
            selectedFilterType: normalizeOption,
          },
        },
        () => {
          this.pushStateQueryString({ type: normalizeOption.label, selectedPage: page });
        },
      );
    }

    return this.setState(
      {
        params: {
          ...params,
          page,
          selectedFilterType: normalizeOption,
        },
      },
      () => {
        this.getSource();
        this.pushStateQueryString({ type: normalizeOption.label, selectedPage: page });
      },
    );
  };

  handleSearchChange = (val: string) => {
    const { showNoOptions } = this.state;
    if (val.length || val.length >= 3) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      setTimeout(async () => {
        await Localities.getCities(val)
          .then((response: string[]) => {
            if (response && response.length) {
              const options: ICityParamFilter[] = response.map((item: string) => {
                const arCityState = item.split('/');
                const cityLabel = item.replace('/', ' - ');
                return {
                  label: cityLabel,
                  value: arCityState[0],
                };
              });
              return this.setState({
                ...this.state,
                filterCityOptions: options,
                showNoOptions: !showNoOptions,
              });
            } else {
              return this.setState({
                ...this.state,
                filterCityOptions: [],
                showNoOptions: false,
              });
            }
          })
          .catch(err => console.error(err));
      }, 300);
    }

    return this.setState({
      ...this.state,
      filterCityOptions: [],
      showNoOptions: false,
    });
  };

  handleCityFilter = (option: ICityParamFilter, hasOutsideControl?: boolean) => {
    const { params } = this.state;
    const { page } = params;
    const cloneParams = params;

    if (cloneParams && cloneParams.selectedFilterLocation) {
      cloneParams.selectedFilterLocation = option;
    }

    this.setState(
      {
        params: cloneParams,
      },
      () => {
        this.pushStateQueryString({ location: option.label, selectedPage: page });
      },
    );

    if (!hasOutsideControl) {
      setTimeout(() => {
        this.getSource();
      }, 300);
    }
  };

  handlePrintAction = (values: any | any[]) => {
    if (_isArray(values)) {
      this.setState(
        {
          ...this.state,
          sourceDataPrint: values,
        },
        () => {
          if (typeof window !== 'undefined') {
            window.print();
          }
        },
      );
    } else {
      const arValues = [values];
      this.setState(
        {
          ...this.state,
          sourceDataPrint: arValues,
        },
        () => {
          if (typeof window !== 'undefined') {
            window.print();
          }
        },
      );
    }
  };

  handleAllPrintAction = () => {
    const {
      params: { searchBoxValue, selectedFilterType, selectedFilterLocation },
    } = this.state;

    const normalizedParams: IUserGetParams = {
      name: searchBoxValue,
      type: selectedFilterType.value,
      city: selectedFilterLocation.value,
    };

    this.setState(
      {
        isLoading: true,
      },
      () => {
        UserService.get(normalizedParams)
          .then((response: any) => {
            const { data } = response;
            this.setState(
              {
                ...this.state,
                sourceDataPrint: data,
              },
              () => {
                this.setState({
                  isLoading: false,
                });
                setTimeout(() => {
                  if (typeof window !== 'undefined') {
                    window.print();
                  }
                }, 300);
              },
            );
          })
          .catch(err => {
            throw new Error(err);
          });
      },
    );
  };

  handleOnMobileFilter = (isReset?: boolean) => {
    const { params } = this.state;
    const cloneParams = params;
    if (isReset) {
      cloneParams.page = 0;
      cloneParams.searchBoxValue = '';
      cloneParams.selectedFilterType = { label: '', value: null };
      cloneParams.selectedFilterLocation = { label: '', value: null };
      this.setState({
        params: cloneParams,
      });
    }
    this.getSource();
  };

  handleRemoveFilter = (val: any) => {
    const { type } = val;
    const { params } = this.state;
    const { page } = params;
    if (type === 'text') {
      this.setState(
        {
          ...this.state,
          params: {
            ...params,
            searchBoxValue: '',
          },
        },
        () => {
          this.pushStateQueryString({ user: '', selectedPage: page });
          this.getSource();
        },
      );
    }

    if (type === 'tipo') {
      this.setState(
        {
          ...this.state,
          params: {
            ...params,
            selectedFilterType: { label: '', value: null },
          },
        },
        () => {
          this.pushStateQueryString({ type: '', selectedPage: page });
          this.getSource();
        },
      );
    }

    if (type === 'localizacao') {
      this.setState(
        {
          ...this.state,
          params: {
            ...params,
            selectedFilterLocation: { label: '', value: null },
          },
        },
        () => {
          this.pushStateQueryString({ location: '', selectedPage: page });
          this.getSource();
        },
      );
    }
  };

  handleRemoveAllFilters = () => {
    const { params } = this.state;
    const { page } = params;
    this.setState(
      {
        ...this.state,
        params: {
          ...params,
          page: 0,
          searchBoxValue: '',
          selectedFilterType: { label: '', value: null },
          selectedFilterLocation: { label: '', value: null },
        },
      },
      () => {
        this.pushStateQueryString({ user: '', type: null, location: '', selectedPage: page });
        this.getSource();
      },
    );
  };

  render() {
    const {
      isLoading,
      source,
      totalCounter,
      sourceDataPrint,
      orderingOptions,
      filterTypeOptions,
      filterCityOptions,
      showNoOptions,
      params: { page, totalPages, searchBoxValue, selectedFilterType, selectedFilterLocation },
    } = this.state;

    const contentCls = cx('noPrint', style.customPaddingTop);

    const dataTableSearchProps = {
      placeholder: 'Pesquise por nome',
      handleChange: this.handleTableSearch,
      initialValue: searchBoxValue,
    };

    const dataTableOrderingProps = {
      label: 'Ordenar',
      icon: 'SvgIconList',
      options: orderingOptions,
      onRequestChange: this.handleOrdering,
    };

    const configCaptionPrint = {
      picture: 'urlCompanyLogo',
      title: 'aliasName',
      description: [
        {
          label: 'Cidade',
          ref: 'city',
        },
        {
          label: 'Estado',
          ref: 'state',
        },
        {
          label: 'Telefone',
          ref: 'phone',
        },
        {
          label: 'Whatsapp',
          ref: 'whatsapp',
        },
      ],
      totalCounter: {
        label: 'anúncios',
        ref: 'advertisementsCount',
      },
    };

    const configColumnPrint = {
      dataRef: 'advertisements',
      columns: [
        {
          label: 'Id',
          ref: 'id',
        },
        {
          label: 'Marca',
          ref: 'model.brandName',
        },
        {
          label: 'Veiculo',
          ref: 'model',
          format: (item: any) => {
            const { name, version } = item.model;
            return `${name} ${version}`;
          },
        },
        {
          label: 'Ano/Modelo',
          ref: 'model',
          format: (item: any) => {
            const { fabricationYear, modelYear } = item.model;
            return `${fabricationYear}/${modelYear}`;
          },
        },
        {
          label: 'Cor',
          ref: 'color',
        },
        {
          label: 'Placa',
          ref: 'plate',
        },
        {
          label: 'C',
          ref: 'typeFuel',
        },
        {
          label: 'KM',
          ref: 'mileage',
        },
        {
          label: 'Valor',
          ref: 'price',
          format: (item: any) => {
            return formatCurrency(item.price);
          },
        },
        {
          label: 'Opcionais',
          ref: 'optionals',
          format: (item: any) => {
            if (_isArray(item.optionals)) {
              return item.optionals.join(', ');
            }
          },
        },
        {
          label: 'Possui foto',
          ref: 'photo',
        },
      ],
    };

    const configFooterPrint = [
      {
        colspan: 11,
        styleClass: 'pv12 tc bt b--alto',
        text: 'Declaro estarem corretas e serem de minha responsabilidade as informações acima citadas',
      },
      {
        columns: [
          {
            colspan: 4,
            label: 'Nome',
            styleClass: 'pv12 tl v-top',
          },
          {
            colspan: 5,
            label: 'Assinatura',
            styleClass: 'pv12 tl v-top',
          },
          {
            colspan: 2,
            label: 'Data',
            text: '____/____/_______',
            styleClass: 'pv12 tl',
          },
        ],
      },
    ];

    const dataTableFiltersProps = {
      mobileLabel: 'Filtrar',
      mobileIcon: 'SvgIconFilter',
      onMobileFilter: this.handleOnMobileFilter,
      options: [
        {
          title: 'Tipo',
          placeholder: 'Selecione um tipo de registro',
          selected: selectedFilterType,
          isSearchable: false,
          options: filterTypeOptions,
          onRequestChange: this.handleTypeFilter,
        },
        {
          title: 'Localização',
          placeholder: 'Selecione uma localidade',
          selected: selectedFilterLocation,
          isSearchable: true,
          options: filterCityOptions,
          handleSearchChange: this.handleSearchChange,
          showNoOptions,
          onRequestChange: this.handleCityFilter,
        },
      ],
    };

    const dataTableColumnsProps = [
      {
        type: 'checkbox',
        ref: 'id',
        actions: [
          {
            id: 1,
            title: 'Imprimir todos',
            label: `todos (${totalCounter})`,
            icon: 'SvgIconPrint',
            request: this.handleAllPrintAction,
          },
          {
            id: 2,
            title: 'Imprimir',
            icon: 'SvgIconPrint',
            request: this.handlePrintAction,
          },
        ],
      },
      {
        title: 'Nome',
        alt: 'aliasName',
        ref: 'urlCompanyLogo',
        type: 'picture',
        width: '50px',
        colspan: 2,
      },
      {
        title: null,
        ref: 'aliasName',
        isTitle: true,
      },
      {
        title: 'Local',
        ref: 'location',
        format: (data: any) => {
          const { city, state } = data;
          if (city && state) {
            return city + ' - ' + state;
          }
        },
      },
      {
        title: 'Tipo',
        ref: 'type',
        format: (data: any) => {
          const { type } = data;
          if (parseInt(type, 10) === 2) {
            return 'Loja';
          }
          if (parseInt(type, 10) === 1) {
            return 'Pessoa';
          }
          return '-';
        },
        columnClass: 'tl',
        width: '150px',
      },
      {
        title: 'Anúncios',
        ref: 'advertisementsCount',
        columnClass: 'tl ph8',
        width: '150px',
      },
      {
        title: 'Plano',
        ref: 'status',
        format: (data: any) => {
          const { status } = data;
          if (status === 1) {
            return 'Ativo';
          }
          if (status === 0) {
            return 'Inativo';
          }
        },
        columnClass: 'tl',
        width: '100px',
      },
      {
        type: 'actions',
        ref: 'actions',
        headerContent: {
          ...dataTableOrderingProps,
        },
        columnContent: [
          {
            title: 'Imprimir',
            icon: 'SvgIconPrint',
            request: this.handlePrintAction,
          },
          {
            title: 'Configurações',
            icon: 'SvgIconConfiguration',
            request: (row: StoreFromService) => {
              const { selectedStore } = this.props;
              if (selectedStore) {
                selectedStore.setClientStore(row);
              }
              navigate('/meus-anuncios/');
            },
          },
        ],
      },
    ];

    const dataTableEmptyState = {
      image: `${process.env.FTP_IMAGENS}images/search/empty-state.png`,
      title: 'Sem Usuários',
      description: 'Nenhum usuário foi encontrado para que seja exibido.',
    };

    const dataTableResumeProps = {
      info: {
        label:
          totalCounter > 1 ? 'resultados' : totalCounter === 0 ? 'nenhum resultado' : 'resultado',
        counter: totalCounter,
      },
      handleRemoveFilter: this.handleRemoveFilter,
      handleRemoveAllFilters: this.handleRemoveAllFilters,
      activeFilters: this.state.activeFilters,
    };
    const renderPagination = () => {
      if (totalPages === 0) {
        return null;
      }

      return (
        <footer className="pt40 pb40 bg-wild-sand">
          <Pagination
            marginClass="ma0"
            forcePage={page}
            onClick={this.handlePaginationClick}
            totalPages={totalPages}
          />
        </footer>
      );
    };

    const renderContent = () => {
      if (isLoading) {
        return <LoaderFullPage />;
      }
      return (
        <>
          <section className={contentCls}>
            <DataTable
              source={source}
              columns={dataTableColumnsProps}
              emptyState={dataTableEmptyState}
              searchContainer={dataTableSearchProps}
              filtersContainer={dataTableFiltersProps}
              orderingContainer={dataTableOrderingProps}
              resumeContainer={dataTableResumeProps}
            />

            {renderPagination()}
          </section>
          <PrintContent
            data={sourceDataPrint}
            caption={configCaptionPrint}
            body={configColumnPrint}
            footer={configFooterPrint}
          />
        </>
      );
    };
    return renderContent();
  }
}
