export const getOrderingOptions = () => {
  return [
    {
      id: 'name-asc',
      label: 'Nome (A - Z)',
      type: 'name',
      value: 'ASC',
      isChecked: false,
    },
    {
      id: 'name-desc',
      label: 'Nome (Z - A)',
      type: 'name',
      value: 'DESC',
      isChecked: false,
    },
    {
      id: 'older-asc',
      label: 'Mais antigos',
      type: 'createDate',
      value: 'ASC',
      isChecked: false,
    },
    {
      id: 'newest-asc',
      label: 'Mais recentes',
      type: 'createDate',
      value: 'DESC',
      isChecked: false,
    },
    {
      id: 'counter-ads-asc',
      label: 'Mais anúncios',
      type: 'advertisementsCount',
      value: 'DESC',
      isChecked: false,
    },
    {
      id: 'counter-ads-desc',
      label: 'Menos anúncios',
      type: 'advertisementsCount',
      value: 'ASC',
      isChecked: false,
    },
  ];
};

export const getFilterTypeOptions = () => {
  return [
    {
      value: 2,
      label: 'Loja',
    },
    {
      value: 1,
      label: 'Pessoa',
    },
    {
      value: 3,
      label: 'Todos',
    },
  ];
};
