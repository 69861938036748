import { axiosInstanceWithAuth } from '../../../services/axios-instance';

interface IUserGetOrderParam {
  by: string | null;
  type: string | null;
}

export interface IUserGetParams {
  page?: number | null;
  take?: number | null;
  type?: number | null;
  name?: string | null;
  city?: string | null;
  order?: IUserGetOrderParam | null;
}

export const UserService = {
  get: async (params?: IUserGetParams) => {
    return await axiosInstanceWithAuth
      .get('user', {
        params,
      })
      .then(response => response)
      .catch(error => {
        throw new Error(error);
      });
  },
};
